import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

const Video = () => (
  <Layout className="wedding-video">
    <SEO title="Wedding Day Video" />
    <h1>Wedding Day Video</h1>
    <div className="video-container">
        <iframe className="video" src="https://www.youtube-nocookie.com/embed/ThBgkEvxiCU" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen="allowFullScreen"></iframe>
    </div>
  </Layout>
)

export default Video
